import React from 'react';
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import Cover from '../assets/sb_dlx.png'
import SBoardRelatedCards from "./SBoardRelatedCards";
import SB_Footer from './SB_Description_Footer';

class SBoardDLXDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>S-Board Delxue</h2>
                        <h3>8 stock modules + 5 additional modules</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner} style={{boxShadow: 'none', maxWidth: '500px', height: 'auto'}}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>14 modules in total, unleashed creativity</p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Bundle</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>S-Board Deluxe</p>
                                <p style={{color: '#6f747f', fontWeight: 300}}>A $263 value</p>
                                <div className={SBDStyle.currPrice}>US $158 (40% off)</div>
                            </div>            
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>13 modules included</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <SBoardRelatedCards currentItem="s-board deluxe"/>

                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBoardDLXDescription