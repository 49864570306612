import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';

import SBoardStyle from '../s-board.module.css';
import Axios from 'axios';
import SBFaq from '../../components/SBoardFaqs';
import { connect } from 'react-redux';
import { add_to_cart } from '../../redux/cart.redux';
import SBoardDLXDescription from '../../components/SBoardDLXDescription';
import { SBoardDLXObj as Obj } from '../../../SBoard_Products';
import { API_URL } from '../../../Global_Config';

class SBoardDeluxePage extends React.Component {
    constructor() {
      super();
      this.handleButtonClick = this.handleButtonClick.bind(this);
    }
  
    getTrial = async() => {
      // this.handleTrackEvent('tryNow');
      // navigate('/downloads');
      if (!this.props.auth.isLoggedIn) {
        navigate('/login', {state: {destination: '/s-board/deluxe'}})
        return;
      }
      if (this.props.user.data.ownedSoftwares.some( i => i.name.toUpperCase() === Obj.licenseName.toUpperCase() )) {
        alert("Please do not reapply for trial.")
      } else {
        let today = new Date()
        let expiresAt = new Date(today.setDate(today.getDate() + 15))
        let obj = {
          email: this.props.user.data.email,
          softwareName: Obj.name.toUpperCase(),
          licenseName: Obj.licenseName,
          authType: 'Trial',
          expiresAt: expiresAt.toISOString().split('T')[0]
        }
        const token = localStorage.getItem('token');
        let config = {
            headers: {
                token: token,
            },
        };
        try {
          await Axios.post(`${API_URL}/software/sendTrialLicense`, obj, config);
          alert("Trail license has been sent to your email.")
        } catch (err) {
          alert(err)
        }
      }
    }
  
    handleButtonClick() {
      this.props.add_to_cart({
        ...Obj, 
        description: `${Obj.tag ? Obj.tag + ", " : ""}${Obj.description}.`,
        quantity: 1,
      });
      navigate('/cart');
    }
  
  
  
    
  
    render() {
      return (
        <Layout location={this.props.location} title={Obj.name.toLowerCase()} showAddToCartOn={this.handleButtonClick} itemName={`BUY ${Obj.currency}${Obj.price}`}>
          <SEO
            title={Obj.name + " - " + Obj.description}
            keywords={[`music`, `vst`, `plugin`, `audio`]}
            description={Obj.oneliner}
            image={Obj.img}
          />
  
          
          
          
          <SBoardDLXDescription addToCart={this.handleButtonClick} getTrial={this.getTrial}/>
          <div className={SBoardStyle.faqContainer} style={{marginTop: '30px'}}>
            <SBFaq />
          </div>
        </Layout>
      )
    }
  }
  
  const mapStatetoProps = (state) => {return {auth: state.auth, user: state.user}};
  const actionCreators = { add_to_cart }
  
  export default connect(mapStatetoProps, actionCreators)(SBoardDeluxePage)
  